import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import { useContacts } from 'hooks';
import { addContact, updateContact } from 'features/contact/contactSlice';
import { parseISO } from 'date-fns';

const contactSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  middleName: Yup.string().nullable(),
  lastName: Yup.string().required(),
  nickName: Yup.string().nullable(),
  address: Yup.string().nullable(),
  city: Yup.string().nullable(),
  postalCode: Yup.string().length(6).nullable(),
  dob: Yup.date().nullable(),
  cellNo: Yup.string()
    .matches(/^[0-9]{10}$/, {
      message: 'Must be 10 digit number only',
      excludeEmptyString: false
    })
    .required('Cell# is required'),
  email: Yup.string().email('Invalid email').nullable(),
  driversLicence: Yup.string().nullable(),
  driversLicenceExpiry: Yup.date().nullable()
});

const ContactManage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contactId } = useParams();
  const { contacts } = useContacts();

  return (
    <div className="p-4">
      <Formik
        initialValues={{
          company: '',
          salutation: '',
          firstName: '',
          middleName: '',
          lastName: '',
          nickName: '',
          address: '',
          city: '',
          postalCode: '',
          cellNo: '',
          email: '',
          driversLicence: '',
          ...contacts[contactId],
          dob:
            (contactId &&
              contacts[contactId]?.dob &&
              parseISO(contacts[contactId]?.dob)) ||
            null,
          driversLicenceExpiry:
            (contactId &&
              contacts[contactId]?.driversLicenceExpiry &&
              parseISO(contacts[contactId]?.driversLicenceExpiry)) ||
            null
        }}
        onSubmit={(values, { setSubmitting }) => {
          // same shape as initial values
          dispatch(contactId ? updateContact(values) : addContact(values))
            .unwrap()
            .then((contact) => {
              setSubmitting(false);
              toast.success(
                `Contact ${values.firstName} ${values.lastName} ${
                  contactId ? 'updated' : 'created'
                }`
              );
              navigate(`/dashboard/contacts/${contact.id}/policies`);
            })
            .catch((error) => {
              console.log(error);
              setSubmitting(false);
              toast.error('Error creating contact');
            });
        }}
        validationSchema={contactSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          values
        }) => (
          <Form>
            <h1 className="text-2xl font-bold mb-6">
              {contactId ? 'Update ' : 'New '} Contact
            </h1>

            <div className="w-64 mb-4">
              <TextField
                defaultValue={values.salutation}
                error={errors.salutation && touched.salutation}
                helperText={
                  errors.salutation && touched.salutation && errors.salutation
                }
                label="Salutation"
                name="salutation"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </div>

            <div className="md:flex">
              <div className="w-64 mb-4 me-2">
                <TextField
                  defaultValue={values.firstName}
                  error={errors.firstName && touched.firstName}
                  helperText={
                    errors.firstName && touched.firstName && errors.firstName
                  }
                  label="First Name *"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </div>

              <div className="w-32 mb-4 me-2">
                <TextField
                  defaultValue={values.middleName}
                  error={errors.middleName && touched.middleName}
                  helperText={
                    errors.middleName && touched.middleName && errors.middleName
                  }
                  label="Middle Name"
                  name="middleName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </div>

              <div className="w-40 mb-4">
                <TextField
                  defaultValue={values.lastName}
                  error={errors.lastName && touched.lastName}
                  helperText={
                    errors.lastName && touched.lastName && errors.lastName
                  }
                  label="Last Name *"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </div>
            </div>
            {/* name */}

            <div className="w-64 mb-4">
              <TextField
                defaultValue={values.nickName}
                error={errors.nickName && touched.nickName}
                helperText={
                  errors.nickName && touched.nickName && errors.nickName
                }
                label="Nick Name"
                name="nickName"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </div>
            {/* nickName */}

            <div className="w-64 mb-10">
              <TextField
                defaultValue={values.company}
                error={errors.company && touched.company}
                helperText={errors.company && touched.company && errors.company}
                label="Company Name"
                name="company"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </div>
            {/* company */}

            <h2 className="text-xl mb-4">Contact Details</h2>

            <div className="w-64 lg:w-80 mb-4">
              <TextField
                defaultValue={values.address}
                error={errors.address && touched.address}
                helperText={errors.address && touched.address && errors.address}
                label="Address"
                name="address"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </div>

            <div className="md:flex">
              <div className="w-64 mb-4 me-1">
                <TextField
                  defaultValue={values.city}
                  error={errors.city && touched.city}
                  helperText={errors.city && touched.city && errors.city}
                  label="City"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </div>

              <div className="w-40 mb-4 me-1">
                <TextField
                  defaultValue={values.postalCode}
                  error={errors.postalCode && touched.postalCode}
                  helperText={
                    errors.postalCode && touched.postalCode && errors.postalCode
                  }
                  label="Postal Code"
                  name="postalCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </div>
            </div>
            {/* address */}

            <div className="w-64 mb-4">
              <TextField
                defaultValue={values.email}
                error={errors.email && touched.email}
                helperText={errors.email && touched.email && errors.email}
                label="Email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                variant="outlined"
                fullWidth
              />
            </div>

            <div className="w-64 mb-8">
              <TextField
                defaultValue={values.cellNo}
                error={errors.cellNo && touched.cellNo}
                helperText={errors.cellNo && touched.cellNo && errors.cellNo}
                label="Cell# *"
                name="cellNo"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </div>
            {/* contactDetails */}

            <h2 className="text-2xl mb-4">Licence Details</h2>

            <div className="mb-4">
              <DatePicker
                autoOk
                error={errors.dob && touched.dob}
                format="dd-MM-yyyy"
                helperText={errors.dob && touched.dob && errors.dob}
                inputVariant="outlined"
                label="Date of Birth"
                name="dob"
                onBlur={handleBlur}
                onChange={(d) => {
                  setFieldValue('dob', d);
                }}
                value={values.dob}
                variant="inline"
              />
            </div>

            <div className="mb-4">
              <DatePicker
                autoOk
                error={
                  errors.driversLicenceExpiry && touched.driversLicenceExpiry
                }
                format="dd-MM-yyyy"
                helperText={
                  errors.driversLicenceExpiry &&
                  touched.driversLicenceExpiry &&
                  errors.driversLicenceExpiry
                }
                inputVariant="outlined"
                label="Drivers Licence Expiry"
                name="driversLicenceExpiry"
                onBlur={handleBlur}
                onChange={(d) => {
                  setFieldValue('driversLicenceExpiry', d);
                }}
                value={values.driversLicenceExpiry}
                variant="inline"
              />
            </div>

            <div className="w-40 mb-6">
              <TextField
                defaultValue={values.driversLicence}
                error={errors.driversLicence && touched.driversLicence}
                helperText={
                  errors.driversLicence &&
                  touched.driversLicence &&
                  errors.driversLicence
                }
                label="Driver License No."
                name="driversLicence"
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </div>

            <Button
              color="primary"
              disabled={isSubmitting}
              disableElevation
              type="submit"
              variant="contained"
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactManage;
