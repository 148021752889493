import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'components/Spinner/Spinner';
import { useForm, Controller } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { setExpiringFilter } from 'features/settings/settingsSlice';
import { parseISO } from 'date-fns';
import { getPoliciesSearch } from 'api/policiesApi';
import { useContacts } from 'hooks';
import { PolicyItem } from 'components';
import { Link } from 'react-router-dom';

const Expiring = () => {
    const dispatch = useDispatch();
    const { control, handleSubmit } = useForm();
    const { filter } = useSelector((state) => state.settings.expiring);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('idle');
    const [result, setResult] = useState(null);
    const { contacts } = useContacts();

    useEffect(() => {
        if (status === 'idle') {
            setLoading(true);
            getPoliciesSearch({
                filterBy: 'coverageUpTo',
                filterFrom: filter.from,
                filterUpTo: filter.upTo
            })
                .then((result) => {
                    setResult(result);
                    setStatus('succeeded');
                })
                .catch((error) => {
                    console.error(error);
                    setStatus('failed');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [status, filter.from, filter.upTo]);

    const handleFormSubmit = ({ from, upTo }) => {
        setStatus('idle');
        dispatch(
            setExpiringFilter({
                from: from.toISOString(),
                upTo: upTo.toISOString()
            })
        );
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-6">Expiring Policies</h1>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="flex flex-wrap items-center">
                    <div className="me-1 mb-4 w-32 sm:w-40">
                        <Controller
                            name="from"
                            control={control}
                            defaultValue={
                                filter.from ? parseISO(filter.from) : ''
                            }
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    label="From"
                                    //renderInput={(params) => <TextField {...params} />}
                                    slotProps={{
                                        textField: { variant: 'outlined' }
                                    }}
                                />
                            )}
                        />
                    </div>

                    <div className="me-1 mb-4 w-32 sm:w-40">
                        <Controller
                            name="upTo"
                            control={control}
                            defaultValue={
                                filter.upTo ? parseISO(filter.upTo) : ''
                            }
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    label="Upto"
                                    //renderInput={(params) => <TextField {...params} />}
                                    slotProps={{
                                        textField: { variant: 'outlined' }
                                    }}
                                />
                            )}
                        />
                    </div>

                    <div className="mb-4">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Filter
                        </Button>
                    </div>
                </div>
            </form>

            {loading ? (
                <Spinner />
            ) : (
                <>
                    {result && (
                        <>
                            {result.length ? (
                                <>
                                    {result
                                        .sort((a, b) => {
                                            if (
                                                a.coverageUpTo < b.coverageUpTo
                                            ) {
                                                return -1;
                                            } else if (
                                                a.coverageUpTo > b.coverageUpTo
                                            ) {
                                                return 1;
                                            }

                                            return 0;
                                        })
                                        .map(
                                            ({
                                                id,
                                                primaryContact,
                                                ...rest
                                            }) => (
                                                <div
                                                    className="border rounded p-4 mb-4"
                                                    key={id}
                                                >
                                                    {contacts?.[primaryContact]
                                                        ?.company ? (
                                                        <>
                                                            <p className="font-bold text-blue-500">
                                                                <Link
                                                                    to={`/dashboard/contacts/${primaryContact}/policies`}
                                                                >
                                                                    {
                                                                        contacts?.[
                                                                            primaryContact
                                                                        ]
                                                                            ?.company
                                                                    }
                                                                </Link>
                                                            </p>
                                                            <p className="mb-2">{`${
                                                                contacts?.[
                                                                    primaryContact
                                                                ]?.firstName
                                                            } ${
                                                                contacts?.[
                                                                    primaryContact
                                                                ]?.middleName ||
                                                                ''
                                                            } ${
                                                                contacts?.[
                                                                    primaryContact
                                                                ]?.lastName
                                                            }`}</p>
                                                        </>
                                                    ) : (
                                                        <p className="mb-2 font-bold text-blue-500">
                                                            <Link
                                                                to={`/dashboard/contacts/${primaryContact}/policies`}
                                                            >{`${
                                                                contacts?.[
                                                                    primaryContact
                                                                ]?.firstName
                                                            } ${
                                                                contacts?.[
                                                                    primaryContact
                                                                ]?.middleName ||
                                                                ''
                                                            } ${
                                                                contacts?.[
                                                                    primaryContact
                                                                ]?.lastName
                                                            }`}</Link>
                                                        </p>
                                                    )}
                                                    <PolicyItem
                                                        policy={{
                                                            id,
                                                            primaryContact,
                                                            ...rest
                                                        }}
                                                    />
                                                </div>
                                            )
                                        )}
                                </>
                            ) : (
                                <p>0 result</p>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default Expiring;
